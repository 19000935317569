.dhs-table{
    margin-bottom: 30px;
    td:first-child{
        font-weight: bold;
        padding-right: 30px;
    }
}

.physical-address{
    font-weight: bold;
}

.section-contact-us {
    .row:nth-child(2){
        margin-left: -45px;
        margin-right: -45px;
        .col{
            padding-right: 45px;
            padding-left: 45px;
            flex-basis: initial;
            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
            }
        }
    }
}
