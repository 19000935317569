/* ACF Google map */

.acf-map {
	height: 1000px;
	border-top-left-radius: 0px;
	&.mini{
		height: 100%;
	}
	// Mobile
	@include media-breakpoint-down(sm) {
		height: 525px !important;
		border-top-left-radius: 0;
	}
	
}

.section-contact-us {
	.acf-map {
		height: 300px;
	}
}