/* Section: Normal content */

.normal-content {
	
	.section-title-mobile {
		display: none;
	}
	
	.col-image {
		padding-top: 0;
		img {
			margin-bottom: (52rem / 16);
			&:first-of-type { 
				border-top-left-radius: 0px; 

			}
			&:last-of-type { 
				border-bottom-right-radius: 0px; 
				margin-bottom: 0;
			}
		}
	}
	
	&.text-on-left {
		.col-image {
			padding-left: inherit;
			padding-left: 4.16%;
		}
	}
		
	&.text-on-right {
		.col-image {
			padding-right: inherit;
			padding-right: 4.16%;
		}
	}
	&.no-images {
		margin-bottom: 0;
	}
	&.no-images:not(:last-child) {
		margin-bottom: -4.5rem;
	}
	
	@include breakpoint-lt(md) {
		&.text-on-left .col-image,
		&.text-on-right .col-image {
			padding-right: 15px;
			padding-left: 15px;
			margin-bottom: 45px;
		}
	}
	
	@include media-breakpoint-down(sm) {
		
		.section-title-mobile {
			display: block;
		}
		.section-title {
			display: none;
		}
	}
	
}