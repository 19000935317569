.two-learn-more{
	background-color: #f8f8f8;
    margin-top: 4.5rem;
    padding-bottom: 5rem;
    margin-bottom: -10rem;
    padding-top: 4.5em;
    h3{
    	margin-bottom: 1em;
    }
    img{
    	width: 100%;
    }
    .overlay-title{
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: calc(100% - 30px);
   		margin: 0 15px;
	    height: 100%;
	    background-color: rgba(0,0,0,.3);
	    text-align: center;
		h5{
	        margin: 0;
	        color: $color-white;
	        position: relative;
		    transform: translateY(-50%);
		    top: 50%;
		    width: fit-content;
    		margin: 0 auto;
	    }
	    a{
	       	color: $color-white;
	       	position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    padding: 0 50px;
		    display: block;
		    &:hover, &:focus{
		    	text-decoration: none;
		    	h5{
		    		&:after{
			    		content:"";
			    		width: 100%;
			    		border-bottom: 4px solid $color-blue-light;
			    		bottom: -5px;
			    		left: 0;
			    		position: absolute;
			    	}
		    	}
		    	
		    }
	    }
    }
    .more-links{
    	margin-top: 15px;
    	&:first-child{
    		.overlay-title, img{
    			border-top-left-radius: 50px;
    		}
    		
    	}
    	&:last-child{
    		.overlay-title, img{
    			border-bottom-right-radius: 50px;
    		}
    	}
    }
}