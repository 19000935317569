/* SECTION Home intro */

.home-intro {
	position: relative;
	font-size: (14rem / 16);
	min-height: 600px;
	overflow-x: visible;
	margin-bottom: 0px;

    .Scroller {
      display: none;
      @include media-breakpoint-up('lg') {
        display: block;
        margin-top: -2rem;
      }
      .js-scroll-home-intro span {
        background-image: url('/wp-content/themes/dhs/assets/images/long-arrow-down.png');
        width: 20px;
        height: 47px;
        background-size: cover;
        display: inline-block;
      }
    }

	.title {
		width: 300px;
		height: 88px;
		margin-top: 207px;
		margin-bottom: 30px;
		background: url('../images/mdh-title.svg') center center no-repeat;	
		
	}
	
	.body {
		font-size: 1rem;
		max-width: 100%;
		left: 0;
	    color: $color-text;
    	z-index: 1;
	    h1{
	    	font-size: 3.5rem;
			color: $color-text;
			position: relative;
			max-width: 575px;

          @include media-breakpoint-up('lg') {
            width: max-content;
          }
	    }
	}
	
	
	
	.intro-image {
	    background: #e6eff0;
		overflow: hidden;
	    z-index: -2;
	    background-repeat: no-repeat;
	    clip-path: none;
	    position: relative;
	    top: 0;
	    right: 0;
	    background-position: center;
	    background-size: cover;
	    border-radius: initial;
		height: 725px;
		border-radius: 100px 0 100px 0;
        width: 100%;

      @media (min-width: 1500px) {
        width: calc(100% + 120px);
      }

      @media (min-width: 1600px) {
        width: calc(100% + 220px);
      }

      @media (min-width: 1750px) {
        width: calc(100% + 300px);
      }
	}

	.location-and-contact{
	    background-color: #fff;
	    position: absolute;
	    right: 15px;
	    bottom: 0;
	    padding: 30px 40px 20px 60px;
	    h3 {
			position: relative;
			font-size: (15rem / 16);
			font-weight: 700;
			position: relative;
	    	z-index: 1;
	    	margin-bottom: .5em;
	    	color: $color-text;
			&::before{
				display: none;
			}
			&::after {
				content:"";
				position: absolute;
			    width: 20px;
			    height: 20px;
			    top: -9px;
			    left: -9px;
			    z-index: -1;
				background-color: $color-white;
				border-radius: 50%;
				border: 6px solid;

			}
		}
		p{
			margin-bottom: 0;
		}
		& > div {
			& > div:nth-child(1){
				h3{
					&::after {
						border-color: $color-green-light;
					}
				}
			}
			& > div:nth-child(2){
				h3{
					&::after {
						border-color: $color-blue-light;
					}
				}
			}
			& > div:nth-child(3){
				h3{
					&::after {
						border-color: $color-green;
					}
				}
			}
		}
	}
	
	@include media-breakpoint-down(lg) {
		min-height: initial;
		.title {
			margin-top: (207px * 0.85);
			width: (300px * 0.85);
			height: (88px * 0.85);
		}

		.intro-image {
			clip-path: none;
		    background-size: cover;
		    top: 0;
		    width: 100%;
		    background-position: center;
			height: 480px;
			
		}
	}
	
	@include media-breakpoint-down(md) {
		.title {
			margin-top: (207px * 0.75);
			width: (300px * 0.75);
			height: (88px * 0.75);
		}
		.body {
			width: 100%;
    		padding: 0 30px;
			h1{
				font-size: 3em;
			}
		}
		.location-and-contact{
			position: relative;
			right: 0;
			padding-left: 15px;
			padding-right: 15px;
				h3{
					color: $color-text;
				}
				p{
					color: $color-text;
					font-size: 1em;
				}
				a{
					color: $color-text;
				}
			
		}
		.intro-image {
			clip-path: none;
		    background-size: cover;
		    top: 0;
		    width: 100%;
		    background-position: center;
			height: 370px;
		}
	}
	
	// Mobile
	@include media-breakpoint-down(sm) {
		min-height: 585px;
		color: #fff;
		font-size: (14rem / 16);
		
		a {
			color: #fff;
		}
		
		&::after {
			display: none;
		}
	
		.title {
			width: 208px;
			height: 61px;
			margin-top: 77px;
			margin-bottom: 16px;
			background: url('../images/mdh-title-white.svg') center center no-repeat;	
		}
		
		.body {
			font-size: 1em;
		    padding: 0;
		    margin-bottom: 30px;
		    margin-top: 30px;
		}
		
		h3 {
			font-size: 1rem;
			color: #fff;
			margin-bottom: 0;
			&::before {
				display: none;
			}
		}
		
		.intro-image {
			&::before {
				@include icon-font;
				content: "\63";
				position: absolute;
				bottom: 40px;
				left: 50%;
				font-size: 27px;
				transform: translateX(-50%);
				color: #fff;
				z-index: 1;
			}
		    clip-path: none;
		    top: 0;
		    width: 100%;
		    height: 585px;
		    transform: none;
		    background-size: cover;
		    background-position: center;
			
			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(0,0,0,0.4);
			}
		}
	}
	
}
