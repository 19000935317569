/* CSS Document */

.slick-outer {
	position: relative;
	
	.slick-wrapper {}
	
	.slick-controls {
		position: absolute;
		background: transparent;
		padding: 10px 5px;
		bottom: 0;
		right: 0;
		
		.control {
			padding: 0;
			/*margin: 0 5px;*/
			display: inline-block;
			/*background-color: $color-blue;*/
    		border-radius: 100%;
    		height: 40px;
    		width: 40px;
			&::before {
				/*@include icon-fontawesome;*/
				/*content: "\f104";*/
				content: url('../images/arrow-left.svg');
				font-weight: 900 !important;
				font-size: (28rem / 16);
				color: $color-white;
			    height: 40px;
			    width: 40px;
			    display: block;
			    text-align: center;
			    line-height: 40px;
			    cursor: pointer;
			}
			
			&.control-next::before {
				content: url('../images/arrow-right.svg');
				/*content: "\f105";*/
				color: $color-white;
			}
			
			&:hover {
				color: $color-white;
			}
		}
	}
	
	// Mobile
	@include media-breakpoint-down(sm) {
		.slick-controls {
			background: none;
			padding: 0;
			bottom: 50%;
			left: 0;
			transform: translateY(50%);
			
			.control {
				padding: 0;
				float: left;
				&::before {
					content: "\f104";
				}
				&.control-next {
					float: right;
					&::before {
						content: "\f105";
					}
				}
			}
		}
	}
	
}