.affix-wrapper{
	position: sticky;
    top: 0px;
}


.sidebar{
  div:first-child{
    box-sizing: content-box;
    padding-top: 0;

    @include media-breakpoint-up('lg') {
      padding-top: 128px;
    }
  }
}
.section-anchored-list {
	.sidebar{
		div:first-child{
			padding-top: 0;
			box-sizing: content-box;
		}
	}
}
.affix{
	top: 0px;
	margin-top: 0;
}	