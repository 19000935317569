.gform_wrapper{
    ul li{
        text-indent: 0em;
    }
    .gform_body{
        width: 100% !important;
        ul.gform_fields{
            display: flex !important;
            flex-wrap: wrap;
            margin-left: -15px !important;
            margin-right: -15px !important;
            .gfield{
                padding: 0 15px !important;
                margin: 0px 0 30px !important;
                &.gfield_html{
                    margin-bottom: 5px !important;   
                }
                &.form-group-half{
                    width: 50%;
                    display: table;
                    .ginput_container{
                        width: 66%;
                        display: table-cell;
                        vertical-align: middle;
                    }
                    > label{
                        width: 34%;
                        display: table-cell;
                        vertical-align: middle;
                        padding-right: 10px;
                    }
                    
                }
                &.form-group-full{
                    width: 100%;
                    display: table;
                    .ginput_container{
                        width: 50%;
                        display: table-cell;
                        vertical-align: middle;
                    }
                    > label{
                        width: 50%;
                        display: table-cell;
                        vertical-align: middle;
                        padding-right: 10px;
                    }
                    &.label-1_4{
                        .ginput_container{
                            width: 83.625%;
                        }
                        > label{
                            width: 16.375%;
                        }
                    }
                }
                &.label-0{
                    .ginput_container{
                        display: block;
                        vertical-align: middle;
                        width: calc(100% + 15px) !important;
                        margin-left: -15px;
                        @include media-breakpoint-down(md) {
                            width: calc(100%) !important;
                            margin-left: 0;
                        }
                    }
                    > label{
                        display: none;
                    }
                }
                @include media-breakpoint-down(md) {
                    @include media-breakpoint-down(sm) {
                        width: 100% !important;
                        display: block !important;
                    }
                    .ginput_container{
                        width: 100% !important;
                        display: block !important;
                    }
                    > label{
                        width: 100% !important;
                        display: block !important;
                    }
                }

                &.gfield_error{
                    background: none !important;
                    border: none !important;
                    .validation_message{
                        display: none;
                    }
                }
                .ginput_container_radio{
                    // padding-top: 15px;
                }
                .gfield_radio{
                    width: 100% !important;
                    display: table;
                    li{
                        display: table-cell;
                        
                        input[type=radio]{
                            margin: 0 !important;
                            border: 1px solid $color-blue;
                            -webkit-appearance: none;
                            width: 16px !important;
                            height: 16px !important;
                            border-radius: 50%;
                            
                            &:checked{
                                border: 6px solid $color-blue;
                                box-shadow: none;
                            }
                        }
                        label{
                            padding-left: 15px !important;
                            line-height: 1em;
                        }
                    }
                }
                &.list-vertical{
                    > label{
                        vertical-align: top;
                    }
                    .gfield_radio{
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0;
                        li{
                            display: block;
                            width: 100%;
                        }
                    }
                }

            }
        }
        input, textarea, select, button{
            width: 100% !important;
            border: 1px solid $color-blue;
            outline: none !important;
        }
        h4{
            margin-top: 1.5em;
            margin-bottom: .5em;    
        }
        p{
            display: none;
        }
        .dhs-table{
            width: 100%;
        }
        .bootstrap-select{
            width: 100% !important;
            display: block;
            button, button:active{
                border-radius: 0;
                background-color: #fff;
                border: 1px solid $color-blue;
                outline: none !important;
                &:after{
                    @include icon-fontawesome;
                    content: "\f107";
                    font-weight: 900 !important;
                    color: $color-blue;
                    border: 0;
                    width: 1em;
                    height: 1em;
                }
            }
            .dropdown-menu{
                margin: 0 !important;
                border-radius: 0;
                padding: 0;
                border: 1px solid $color-blue;
                li{
                    padding: 0;
                    a{
                        background-color: $color-white;
                        &:hover, &.active{
                            color: $color-white;
                            background-color: $color-blue;
                        }
                    }
                }
            }
        }
    }
    .gform_button{
        margin-top: .8rem !important;
        display: inline-block;
        background-color: $color-blue;
        color: #fff;
        padding: 10px 30px;
        border-radius: 50px;
        text-transform: uppercase;
        font-size: 0.75em !important;
        margin-bottom: 40px;
        outline: none !important;
        cursor: pointer;
        min-width: 150px;
        text-align: center;
        &:after {
            @include icon-font;
            content: "\67";
            margin-left: 0.7em;
            color: $color-blue;
            transition: 0.3s;
            display: none;
        }
        &:hover {
            color: #fff;
            text-decoration: none;
            &::after {
                margin-left: 1.05em;
                color: $color-green;
            }
        }
    }
    .gform_confirmation_message {
        background-color: $color-blue;
        color: $color-white;
        padding: 25px 30px;
    }
}

.gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half){
    max-width: none !important;
}

.gform_footer br, .gform_footer p {display: none}