/* CSS Document */

$color-blue: #649FF4;
$color-green: #32AA4E;

$color-blue-light: #A2E3F2;
$color-green-light: #A2C84A;

$color-blue-dark: #006D71;


$color-text: #000;
$color-white: #fff;