/* Section: Image with text */

.image-with-text {
	
	.section-title-mobile {
		display: none;
	}
	
	.col-text {
		padding-top: 0;
	}
	
	.col-image {
		img {
			border-radius: 0px 0 0 0;
		}
	}
	
	&.text-on-left {
		&::before {
			position: absolute;
			display: block;
			content: '';
			background: $color-green-light;
			top: 12rem;
			left: 0;
			right: calc(50% + 300px);
			border-radius: 0 200px 200px 0;
			height: 400px;
			display: none;
		}
		
		.col-text {
			padding-right: 60px;
			padding-top: 0;
		}
	}
	
	&.text-on-right {
		
		&::before {
			position: absolute;
			display: block;
			content: '';
			background: $color-blue-light;
			top: 12rem;
			right: 0;
			left: calc(50% + 300px);
			border-radius: 200px 0 0 200px;
			height: 400px;
			display: none;
		}
		
		.col-text {
			padding-left: 20px;
			padding-right: 60px;
			padding-top: 0;
		}
	}
	&.cascading {
		.text-on-right {
			.col-image {
				padding-right: 50px;
				img {
					border-radius: 0px 0 0 0;
				}
			}
			.col-text {
				padding: 0 15px;
				padding-top: 0;
			}
		}
		.text-on-left {

			.row{
				justify-content: flex-end;
			}
			.col-image {
				padding-left: 50px;
				padding-right: 0px;
				img {
					border-radius: 0px 0 0 0;
				}
			}
			.col-text {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		section:nth-child(2){
			margin-top: 0;
		}
	}

	@include media-breakpoint-down(lg) {
		&.cascading {
			.text-on-right, .text-on-left {
				margin-bottom: 40px;
			}
		}
		&.text-on-left {
			&::before {
				top: 9rem;
				right: calc(50% + 250px);
			}
			.col-text {
				padding-top: (60rem / 16);
				padding-right: (60rem / 16);
			}
		}
		&.text-on-right {
			&::before {
				top: 9rem;
				left: calc(50% + 250px);
			}
			.col-text {
				padding-top: (60rem / 16);
				padding-left: 1.75rem;
			}
		}
	}

	@include media-breakpoint-down(md) {
		
		&.text-on-left {
			&::before {
				top: 8rem;
				right: calc(50% + 120px);
			}
			.col-text {
				padding-top: 0;
				padding-right: (40rem / 16);
				padding: 0 15px;
				margin: 0;
			}
		}
		&.text-on-right {
			&::before {
				top: 8rem;
				left: calc(50% + 120px);
			}
			.col-text {
				padding-top: 0;
				padding-left: 1rem;
			}
		}
		&.cascading{
			.text-on-left .col-image{
				padding-left: inherit;
			}
			section:nth-child(2){
				margin-top: 0px;
			}
		}
		@include media-breakpoint-up(sm) {
			&.text-on-left {
				.col-text{
					margin-bottom: 30px;
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		
		.section-title-mobile {
			display: block;
		}
		.section-title {
			display: none;
		}
		
		&.text-on-left,
		&.text-on-right {
			&::before {
				
			}
			.col-text {
				padding: 24px 15px 0 15px;
			}
		}
		
		&.text-on-left {
			&::before {
				right: 20%;
				border-radius: 0 290px 290px 0;
				height: 580px;
			}
		}
		
		&.text-on-right {
			&::before {
				left: 20%;
				border-radius: 290px 0 0 290px;
				height: 580px;
			}
		}
		
		.order-12 { // put image back in order
		    -ms-flex-order: 0;
    		order: 0;
		}
		
		.col-image {
			padding: 0 15px;
			img {
				border-radius: 0;
				margin-bottom: 30px;
			}
		}
	}
	
}

