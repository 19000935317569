/* Section: Full-with image */

.full-width-image {
	
	.col-image {
		img {
			border-radius: 0px 0 0 0;
		}
	}
	
}