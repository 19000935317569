.achored-list-content{
    padding-top: 160px;
    margin-top: -90px;
    padding-bottom: 80px;
    border-bottom: 1px solid $color-blue;
    z-index: 0;
    position: relative;
    &:first-child{
        padding-top: 90px;
    }
    &:last-child{
        border-bottom: 0px solid $color-blue;
    }
}

.contact-us{
    h5{
        color: $color-text;
        margin: 30px 0 10px;
    }
}