/* Footer */

footer {
	position: relative;
	background: $color-blue-light;
	padding: 64px 0;
	font-size: 12px;
	line-height: (20 / 12);
	margin-top: 0;
	background: $color-blue;
	background-size: cover;
	color: $color-white;
	font-size: 1em;
	& > .container{
			&:after{
				content: url('../images/pattern-3.svg');
				position: absolute;
				right: 0;
				width: 140px;
				top: 0;
			}
	}
	.footer-logo {
		img{
			width: auto;
			height: 50px;
		}
	}
	a {
		color: $color-white;
		text-decoration: none;
		&:hover, &:focus{
			color: $color-white;
		}
	}
	
	nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
	    text-align: center;
		a {
			display: inline-block;
    		margin: 0 25px;
			font-size: .875rem;
            margin-bottom: 25px;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
		}
	}
	p{
		font-size: 12px;
	}
	.footer-tools {
		font-size: .75rem;
		padding-top: 40px;
		text-align: center;
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			margin-left: -15px;
			margin-right: -15px;
			
		}
	}
	
	
	@include media-breakpoint-down(sm) {
		text-align: center;
		font-size: 1rem;

		& > .container{
			&:after{
			display: none;
			}
		}
		.footer-logo {
			margin-bottom: 20px;
			img{
				margin-left: auto;
				margin-right: auto;
			}
		}
		.hours-row {
			.day { 
				text-align: left; 
				width: 110px; 
			}
			.hours { 
				text-align: right; 
			}
		}
		nav a {
			padding: 4px 0;
		}
		.footer-tools {
			padding-top: 20px;
		}
	}
}