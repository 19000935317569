header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	
	background: #fff;
	box-shadow: 0 4px 16px rgba(0,0,0,0.1);
	font-size: 14px;
	padding: 0px 0;
	.row{
		align-items: center;
	}
	.header-logo {
		height: 62px;
	}
	
	.header-menu {
		text-align: right;
	}
	
	nav {
		line-height: 50px;
		a {
			display: inline-block;
			margin: 0 20px;
			color: $color-text;
			font-weight: bold;
			margin-right: 20px;
			margin: 0;
			padding: 20px 15px;
			&:last-of-type{
				margin: 0;
			}
			&:hover {
				color: $color-text;
				text-decoration: none;
			}
			
			&.current-menu-item {
				background: none;
				position: relative;
				&:before{
					display: none;
				}
			}
		}
		& > li{
			display: inline-block;
			position: relative;
			&.current-menu-item, &.current-menu-ancestor, &:hover, &:focus{
				position: relative;
				&:before{
					display:block !important;
					content: url('../images/header-hightlighter.svg');
				    position: absolute;
					background: 50% no-repeat;
					width: 15px;
					height: 15px;
					top: 50%;
					left: 0;
					z-index: -1;
					bottom: 15px;
					transform: translateY(calc(-50% - 15px));
				}
			}
			& > a{
				padding: 15px 25px;
			}
			&:last-child > a{
				padding-right: 0;
			}
			& > ul {
				display: none;
				width: max-content;
			    padding: 0;
			    text-align: left;
				position: absolute;
				background-color: $white;
    			box-shadow: 3px 3px 6px 0 #e8e8e8;
    			> li{
    				list-style: none;
    				> a{
    					width: 100%;
    					padding: 0 20px !important;
    					&:hover, &:focus{
    						background-color: $color-blue;
    						color: $color-white;
    					}
    				}
    				&.current-menu-item, &.current_page_parent{
    					min-height: 50px;
    					> a{
    						background-color: $color-blue;
    						color: $color-white;
    						line-height: 1.25em;
						    min-height: 50px;
							padding: 16px 20px !important;
    					}
    					
    					
    				}
					> ul{
						display: none;
					    position: absolute;
					    left: 100%;
					    width: max-content;
					    padding: 0;
					    top: 0;
					    z-index: 1;
					    box-shadow: 3px 3px 6px 0 #e8e8e8;
					    li{
					    	list-style: none;
					    	list-style: none;
						    align-items: center;
						    display: flex;
						    min-height: 50px;
					    	a{
					    		width: 100%;
    							padding: 0 20px !important;
					    		background-color: $color-white;
								color: $color-text;
								line-height: 1.25em;
								min-height: 50px;
								padding: 16px 20px !important;
					    	}
					    	&.current-menu-item{
					    		a{
		    						background-color: $color-blue;
		    						color: $color-white;
		    						line-height: 1.25em;
								    min-height: 50px;
									padding: 16px 20px !important;
		    					}
					    	}
					    	&:hover{
		    					min-height: 50px;
		    					a{
		    						background-color: $color-blue;
		    						color: $color-white;
		    						line-height: 1.25em;
								    min-height: 50px;
									padding: 16px 20px !important;
		    					}
		    				}
					    }
					}
					&:hover{
						> ul{
    						display: block;
    					}
					}
    			}
			}
			&:hover{
				> ul{
					display: block;
				}
			}
			&:last-child{
				> ul{
					li{
						ul{
							right: 100%;
							left: initial;
							box-shadow: -3px 3px 6px 0 #e8e8e8;
						}
					}
				}
			}
		}
	}
	@include media-breakpoint-down(lg) {
		.container{
			max-width: none;
		}
		nav a {
			margin: 0 15px;
		}
	}
	
	@include media-breakpoint-down(md) {
		font-size: 12px;
		
		nav a {
			margin: 0 8px;
		}
	}
	
	
	
	@include media-breakpoint-up(md) {
		.header-mobile-controls {
			display: none;
		}
	}
	@include media-breakpoint-down(md) {
		.header-mobile-controls {
			display: block;
		}
	}
	
	// Mobile
	@include media-breakpoint-down(md) {
		padding: 10px 0;
		.header-menu {
			text-align: left;
			position: absolute;
			top: 70px;
			left: 0;
			right: 0;
			height: calc( 100vh - 70px );
			background: initial;
			height: 0;
			overflow-y: auto;
			transition: 0.5s;
			display: none;
			a {
				display: block;
				font-size: (28rem / 16);
				margin: 12px 0 12px 40px;
				
				&.current-menu-item {
					position: relative;
					background: none;
					z-index: 2;
					&::after{
						position: absolute;
						top: 0;
						left: 0;
						content: '';
						display: block;
						width: 54px;
						height: 45px;
						background: initial;
						opacity: 0.5;
						z-index: -1;
					}
					
				}
			}
			.menu-phone {
				display: none;
			}
		}
		
		.header-logo {
			height: 50px;
		}
		
		.header-mobile-controls {
			position: absolute;
			right: 0;
			top: -15px;
		}
		
		.mobile-menu-phone {
			display: inline-block;
			line-height: 70px;
			vertical-align: top;
			font-size: 1rem;
			color: $color-blue-dark;
		}
		
		.mobile-menu-toggle {
			position: relative;
			display: inline-block;
			padding: 22px;
			height: 70px;
			width: 70px;
			span {
				position: relative;
				transform-origin: 50% 50%;
				display: block;
				height: 5px;
				border-radius: 2.5px;
				background: $color-blue;
				transition: 0.5s;
			}
			span:first-of-type {
				background: $color-green;
				margin-bottom: 5px;
			}
			span:nth-of-type(2) {
				width: 80%;
				margin-bottom: 5px;
			}
			span:last-of-type {
				background: $color-blue-dark;
			}
		}
	}
}
@include media-breakpoint-down(md) {
	.mobile-menu-open {
		body {
			overflow: hidden;
		}
		
		header .mobile-menu-toggle {
			span:first-of-type {
				margin-bottom: 0;
				width: 110%;
				transform: translateY(10px) rotate(45deg);
			}
			span:nth-of-type(2) {
				width: 110%;
				margin-bottom: 0;
				transform: translateY(5px) rotate(-45deg);

			}
			span:last-of-type {
				opacity: 0;
			}
		}
		
		.header-menu {
			height: calc( 100vh - 70px );
			width: 100%;
			max-width: none;
			background-color: $color-white;
			display: block;
			top: 55px;
			nav{
				width: 300px;
				margin: 0 auto;
				font-size: 16px;
				li{
					width: 100%;
					position: relative;
					a{
						padding: 0 20px;
						font-size: 1em;
						margin: 0;
					}
					&.menu-item-has-children{
						&:hover:before{
							display: none !important; 
						}
						> .mobile-expander{
							width: 50px;
							height: 50px;
							position: absolute;
							right: 0;
							top: 0;
							z-index: 2;
							transition: all .5s ease;
							&:after{
								@include icon-fontawesome;
								content: "\f107";
								font-weight: 900 !important;
								position: absolute;
								font-size: 1.5em;
								top: 50%;
								transform: translateY(-50%);
								z-index: 1;
								color: $color-blue;
								width: 50px;
								height: 50px;
								text-align: center;
								line-height: 50px;
								cursor: pointer;
							}
						}
						ul{
							position: relative;
							width: 100%;
							display: none;
							height: 0;
							overflow: hidden;
							margin: 0;
							padding: 0;
							padding-left: 15px;
							transition: all .5s ease;
							box-shadow: none;
							left: 0;
							li{
								width: 100%;
							}
						}
						&.expanded{
							ul{
								display: block;
								height: initial;
								li{
									a:hover{
										background-color: $color-white;
										color: $color-text;
									}
									ul{
										height: 0;
										overflow: hidden;
										
									}
									&.expanded{
										ul{
											display: block;
											height: initial;
										}
									}
								}
							}
							> .mobile-expander{
								transform: rotate(180deg);
							}
						}
					}
				}
			}
		}
	}
}