.child-list{
	padding: 0;
	margin: 0;
	li{
		list-style: none;
		position: relative;
		a{
			padding: 15px 0;
			display: inline-block;
			font-weight: bold;
			&:hover, &:focus{
				text-decoration: none;
			}
		}
		&.active, &:hover{
			a{
				color: $color-text;
				&:before{
					content: "";
					display: block;
					border-top: 2px solid $color-blue-light;
					position: absolute;
					width: 100%;
					bottom: 10px;
				}
			}
		}
	}
}


