/* Typography */

body {
	@include font-proxima-nova();
	font-size: 16px;
	color: $color-text; 
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
	@include font-alliased();
	margin-bottom: 1.5em;
	line-height: 1.25em;
	a{
		&:hover, &:focus{
			text-decoration: none;
		}
	}
}

h1 {
	font-size: (48rem / 16);
	margin-bottom: 0.6em;
}

h2 {
	font-size: (34rem / 16);
	margin-bottom: 1em;
}

h3 {
	font-size: (30rem / 16);
	color: $color-text;
}
h4 {
	font-size: 1.275em;
	color: $color-blue;
	padding-bottom: .5em;
	line-height: 1.5em;
}
h5 {
	color: $color-blue;
	padding-bottom: 0em;
}
a {	
	color: $color-text;
	&:hover {
		color: $color-blue-dark;
	}
}

*{
	position: relative;
}

P{
	margin-bottom: 1.5rem;
}
blockquote {
	border-left: 3px solid $color-blue;
	padding-left: 37px;
	font-weight: 500;
	font-size: (20rem / 16);
	
	cite {
		display: block;
		font-weight: 800;
		font-size: (17rem / 16);
		font-style: normal;
		margin-top: 1rem;
		
		.cite-image {
			width: 49px;
			height: 49px;
			border-radius: 50%;
			margin-right: 30px;
		}
	}
}

.col-text {
	line-height: (27 / 16);
	a{
		color: $color-text;
		text-decoration: underline;
	}
	ul {
		margin-left: 0;
		padding-left: 0;
		list-style: none;
		margin-bottom: 1.5rem;
		li {
			padding-left: 1.5em;
			text-indent: 0;
			&::before {
				
				content: url('../images/point.svg');
				font-size: 1em;
				line-height: 1.5rem;
				width: .75em;
				height: .75em;
				border-radius: 50%;
				position: absolute;
				left: 0;
			}
		}
	}	
	h3:not(:first-child) {
		margin-bottom: 25px;
		margin-top: 30px;
	}
}


.two-learn-more , .section-contact-us{
    h5{
        margin: 30px 0 15px;
        font-size: 1.5em;
        color: $color-text;
    }
}

.cv-download{
	font-weight: bold;
	padding-left: 40px;
	&:before{
		content:url("../images/download-icon.svg");
		width: 28px;
		height: 28px;
		position: absolute;
		left: 0;
	}
}