/* Main */

.main {
	padding-top: 90px;
	@include media-breakpoint-down(sm) {
		padding-top: 70px;
	}
    margin-bottom: 50px;
    @include media-breakpoint-up('md') {
      margin-bottom: 160px;
    }
}

img{
	display: block;
	&.alignright {
		float:right;
		margin-left: 60px;
		margin-bottom: 15px;
	}

	&.alignleft {
		float:left;
		margin-right: 60px;
		margin-bottom: 15px;
	}
	@include media-breakpoint-down(md) {

		&.alignright {
			float:none;
			margin-left: 0;
			margin-bottom: 15px;
		}

		&.alignleft {
			float:none;
			margin-right: 0;
			margin-bottom: 15px;
		}
	}
}

.space-between{
	display: flex;
	justify-content: space-between;
}

.align-items-center{
	display: flex;
	align-items: center;
}
.position-relative{
	position: relative;
}

.font-semibold{
	font-weight: 500;
}