.thumbnail{
    cursor: pointer;
    img{
         width: 100%;
    }
    &.youtube:before {
        content: url('../images/play-button.svg');
        position: absolute;
        width: 50px;
        height: 50px;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    &.team_profile{
        img{
            border-top-left-radius: 50px;
            border-bottom-right-radius: 50px;

        }
        .profile-description-wrapper{
                position: absolute;
                top: 0;
                left: 0;
                text-align: center;
                background-color: rgba(0,0,0,.3);
                width: 100%;
                height: 100%;
                border-top-left-radius: 50px;
                border-bottom-right-radius: 50px;
                padding: 20px;
            .profile-description{
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                h5{
                    color: $color-white;
                    margin-bottom: .5em;
                    font-size: 1.5rem;
                }
                p{
                    color: $color-white;
                    margin-bottom: 0;
                }
            }
        }
    }
}
.modal-video{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.7);
    display: none;
    z-index: 1;
    &.active{
        display: block;
    }
    .video-wrapper{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        max-width: 600px;
        width: 100%;
        .modal-close{
            width: 50px;
            height: 50px;
            position: absolute;
            top: -60px;
            right: 0;
            content: "x";
            color: $color-white;
            line-height: 50px;
            font-size: 40px;
            text-align: center;
            cursor: pointer;
        }
        iframe{
            width: 100%;
        }
    }
}

.section-media{
    &:not(:last-child) {
		margin-bottom: -4.5rem;
	}
    .media-item{
        margin-bottom: 30px;
    }
}