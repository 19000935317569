.accordion{	
	.card{
		margin-bottom: 20px;
		border: 0;
		background-color: transparent;
		.title{
			padding: 30px 30px;
    		border-bottom: 1px solid $color-blue;
    		background-color: $color-blue;
    		position: relative;
			cursor: pointer;
    		user-select: none;
    		transition: .3s all ease;
			h4{
    			font-weight: bold;
				letter-spacing: .05em;
				margin: 0;
				text-transform: uppercase;
				padding-right: 95px;
				color: $color-white;
				padding: 0;
			}
			&:after{
				@include icon-fontawesome;
				content: "\f106";
				font-weight: 900 !important;
				position: absolute;
			    right: 30px;
			    font-size: 2em;
			    top: 50%;
			    transform: translateY(-50%);
			    z-index: 1;
    			color: $color-white;
			}
		}
		.card-content{
			height: initial;
			overflow: hidden;
			padding: 30px 30px;
			box-shadow: 0px 0px 20px -5px #999;
			transition: .3s all ease;
			background-color: $color-white;
		}
		&.showless{
			.title{
				background-color: transparent;
				h4{
					color: $color-text;
				}
				&:after{
					content: "\f107";
					color: $color-blue;
				}
			}
			.card-content{
				height: 0;
				padding: 0px 30px;
			}
		}
		&:last-child{
			.title{
				border-bottom: 0px solid $color-blue;
			}
		}
	}
}