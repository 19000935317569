/* CSS Document */

.learn-more, .load-more {
	margin-top: 0;
    display: inline-block;
    background-color: $color-blue;
    color: $color-white !important;
    padding: 10px 30px;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 0.75em;
	margin-bottom: 0;
	cursor: pointer;
	text-decoration: none !important;
	&::after {
		@include icon-font;
		content: "\67";
		margin-left: 0.7em;
		color: $color-blue;
		transition: 0.3s;
		display: none;
	}
	&:hover {
		color: #fff;
		text-decoration: none;
		background-color: $color-blue-light;
		&::after {
			margin-left: 1.05em;
			color: $color-blue-light;
		}
	}
}

.load-more{
	margin: 0 auto;
	display: table;
}