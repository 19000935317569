.section-timeline{
    .timeline-wrapper{
        margin-top: 60px;
        img{
            margin-bottom: 30px;
        }
    }
    .timeline-item:not(:last-child){
        .timeline-content-wrapper{
            padding-bottom: 100px;
        }
        .timeline-year-wrapper{
            &:before{
                content:"";
                height: 100%;
                border-right: 2px solid $color-blue;
                position: absolute;
                left: 50%;
            }
        }

    }
    
    .timeline-year{
        color: $color-white;
        background-color: $color-blue;
        border-radius: 100%;
        width: 100%;
        padding-top: 100%;
        .year{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            width: 100%;
            text-align: center;
        }
    }
    table{
        margin-bottom: 40px;
        p{
            margin-bottom: 0;
        }
        td{
            vertical-align: top;
            &:first-child{
                font-weight: bold;
                padding-right: 15px;
            }
        }
    }
}