/* Common section attributes */

.full-width-image,
.image-with-text,
.news-list,
.normal-content,
.row-of-image-links,
.section-contact-us,
.contact,
.accordion,
.two-learn-more,
.section-resources,
.section-timeline,
.section-media,
.section-button,
.section-logos,
.webform-section-wrapper {
	position: relative;
	padding-top: 110px;
	z-index: 1;
	&.first_section{
		padding-top: 128px;
		@include media-breakpoint-down(md) {
			padding-top: 4rem;
		}
	}
	
	@include media-breakpoint-down(md) {
		padding-top: 6rem;
	}
}

// Larger title for first section on page
.first-on-page h2, h1 {
	font-size: (48rem / 16);
	margin-bottom: 0.6em;
}

section.full-width-image {
    margin-bottom: 30px;
}

.section-anchored-list{
	padding-top: 120px;
}

.section-button {
  padding-top: 5px;
}

.section-button + section.section-media {
  padding-top: 50px;
}

.section-logos + section.two-page-link {
  margin-top: 60px;
  @include media-breakpoint-up('sm') {
    margin-top: 120px;
  }
}
