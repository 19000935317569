.two-page-link{
    margin-top: 60px;
    h4{
        color: $color-text;
        margin: 0
    }
    .page-link-wrapper{
        position: relative;
        overflow: hidden;
        a{
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            color: $color-white;
            text-align: center;
            background-color: rgba(0, 0, 0, .3);
            font-size: 1.25rem;
            &:hover, &:focus {
                text-decoration: none;
            }
            span{
                top: 50%;
                position: absolute;
                transform: translateY(-50%) translateX(-50%);
                left: 50%;
                width: 100%;
                padding: 15px;
            }
        }
        img{
            width: 100%;
        }
    }
    .container > .row{ 
        &:before{
            content: url('../images/pattern-1.svg');
            position: absolute;
            right: 0;
            width: 25%;
        }
        .page-link-item{
            &:nth-child(1){
                .page-link-wrapper{
                    border-top-left-radius: 100px;
                }
            }
            &:nth-child(2){
                .page-link-wrapper{
                    border-bottom-right-radius: 100px;
                }
            }
        }
    }
    &.text-on-right{
        .container > .row{ 
            justify-content: flex-end;
            &:before{
                content: url('../images/pattern-2.svg');
                position: absolute;
                left: 0;
                right: initial;
                width: 25%;
            }
        }
    }
    @include media-breakpoint-down(md) {
        h4{
            font-size: 2rem;
        }
    }
}