/* News card */

.news-card {
	position: relative;
	display: block;
	min-height: (270px + 50);
	margin-bottom: 2em;
	
	.image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 270px;
		background: none;
		background-size: cover;
		border-radius: 0 0 0 0;
		z-index: -1;
		background-position: center;
	}
	
	.content {
		position: absolute;
		bottom: 0;
		right: 0;
		background: #fff;
		width: calc(100% - 75px);
		padding: 24px 0 24px 40px;
		font-size: (20rem / 16);
		
		.title {
			font-weight: 700;
			@include font-alliased();
			line-height: (27 / 20);
		}
		.date{
			@include font-alliased();
			font-size: .75em;
		}
	}
	
}